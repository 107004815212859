import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import useSWR from 'swr';

import logo from '../../assets/img/logo.svg';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { type Organization, type Organizer } from '../../types';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { LegalDisclaimer } from '../common/Utilities';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';
import { useUser, useUserContext } from '../UserContext';
import { useRedirectTo } from './hooks';
import { MarketingLayout } from './MarketingLayout';

function NotificationCheckbox(props: {
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const handleChange = async (value: boolean) => {
    if (value) {
      props.onChange(true);
      return;
    }

    const resp = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='p-5 text-white text-center'>
          <p className='text-2xl font-medium'>
            Are you sure you don’t want to receive important notices?
          </p>
          <p className='mt-4 text-sms'>
            These notices via email and slack help to facilitate the experience.
            We make it easy to unsubscribe later if it’s not working for you.
          </p>
        </div>
      ),
      confirmBtnLabel: '😢 Opt Out',
      confirmBtnVariant: 'delete',
      cancelBtnLabel: 'Cancel',
    });
    if (resp.result === 'canceled') return;

    props.onChange(false);
  };

  return (
    <input
      type={'checkbox'}
      className='checkbox-dark'
      name={props.name}
      checked={props.value}
      onChange={(e) => handleChange(e.target.checked)}
    />
  );
}

interface FormData {
  firstName: string;
  lastName: string;
  notificationEnabled: boolean;
  learningGroupId?: string;
}

const maxNameLength = 50;

type LearningGroupOption = {
  label: string;
  value: string;
};

function useLearningGroups(groupIds: string[]) {
  return useSWR(
    groupIds ? ['/learning/groups', groupIds] : null,
    async () => {
      if (!groupIds) return [];
      const response = await apiService.learning.getOrgGroupsByIds(groupIds);
      const groups = response.data.groups ?? [];
      return groups
        .map((group) => ({
          label: group.name,
          value: group.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    },
    {
      revalidateOnFocus: false,
    }
  );
}

function LearningGroupSelect({
  groupIds,
  value,
  onChange,
  error,
  namingConvention,
}: {
  groupIds: string[];
  value?: string;
  onChange: (value: string | null) => void;
  error?: { message?: string };
  namingConvention?: string;
}) {
  const {
    data: learningGroups,
    isLoading,
    error: loadError,
  } = useLearningGroups(groupIds);
  const hasGroups = learningGroups && learningGroups.length > 0;

  if (!hasGroups && !isLoading) {
    return null;
  }

  const selectStyles = buildReactSelectStyles<LearningGroupOption, false>({
    override: {
      control: {
        minHeight: 50,
        height: 50,
      },
      option: (provided) => ({
        ...provided,
        color: 'white',
        backgroundColor: '#222',
        '&:hover': {
          backgroundColor: '#333',
        },
      }),
    },
  });

  if (isLoading) {
    return (
      <div className='h-12.5 flex items-center'>
        <Loading text='Loading groups...' />
      </div>
    );
  }

  return (
    <div className='w-full'>
      <Select<LearningGroupOption, false>
        classNamePrefix='select-box-v2'
        className='h-12.5 select-container'
        options={learningGroups}
        placeholder={`Select your ${namingConvention || 'Group'}`}
        value={learningGroups?.find((option) => option.value === value)}
        onChange={(option) => {
          onChange(option?.value || null);
        }}
        styles={selectStyles}
        isSearchable={true}
        isLoading={isLoading}
        isDisabled={isLoading}
      />
      {error && (
        <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs'>
          {error.message}
        </div>
      )}
      {loadError && (
        <div className='px-2 pt-1 text-left text-red-005 text-3xs'>
          Failed to load groups
        </div>
      )}
    </div>
  );
}

function ActivationForm(props: {
  defaultValues?: FormData;
  onSubmit: (data: FormData) => Promise<void>;
  formError?: Error | null;
  organization: Organization;
}): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    control,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: props.defaultValues,
  });

  const { organization } = props;

  const groupIds = organization.settings?.learningGroups?.learningGroupIds;
  const groupsEnabled = organization.settings?.learningGroups?.enabled;

  return (
    <form
      onSubmit={handleSubmit(props.onSubmit)}
      className='w-full flex flex-col gap-2.5'
    >
      <fieldset disabled={isSubmitting} className='flex flex-col gap-2.5'>
        <label className='w-full'>
          <input
            className={`w-full h-12.5 ${
              errors.firstName ? 'field-error' : 'field'
            } mb-0`}
            maxLength={maxNameLength}
            placeholder='First Name'
            {...register('firstName', {
              required: true,
              maxLength: maxNameLength,
            })}
          />
          {errors.firstName && (
            <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs'>
              Must be 1 to {maxNameLength} characters
            </div>
          )}
        </label>

        <label className='w-full'>
          <input
            className={`w-full h-12.5 ${
              errors.lastName ? 'field-error' : 'field'
            } mb-0`}
            maxLength={maxNameLength}
            placeholder='Last Name'
            {...register('lastName', {
              required: true,
              maxLength: maxNameLength,
            })}
          ></input>
          {errors.lastName && (
            <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs'>
              Must be 1 to {maxNameLength} characters
            </div>
          )}
        </label>

        {groupIds && groupsEnabled && (
          <Controller
            control={control}
            name='learningGroupId'
            rules={{ required: 'Please select a group' }}
            render={({ field }) => (
              <LearningGroupSelect
                groupIds={groupIds}
                value={field.value}
                onChange={field.onChange}
                error={errors.learningGroupId}
                namingConvention={
                  organization.settings?.learningGroups?.namingConvention
                }
              />
            )}
          />
        )}
      </fieldset>

      {props.formError && (
        <div className='px-2 pt-1 text-left text-red-005 text-3xs'>
          {err2s(props.formError)}
        </div>
      )}

      <div className='bg-dark-gray h-10 rounded-xl mt-5 w-full mb-1'>
        <button
          type='submit'
          disabled={isSubmitting || !isValid}
          className='btn-primary px-12 h-10 flex justify-center items-center gap-2 w-full'
        >
          {isSubmitting && <Loading text='' />}
          Continue
        </button>
      </div>

      <label className='w-full flex items-center gap-2 mt-5 mb-1'>
        <Controller
          control={control}
          name='notificationEnabled'
          render={({ field }) => (
            <NotificationCheckbox
              name='notificationEnabled'
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <p className='text-sms font-normal text-secondary'>
          Receive occasional & important notices like game launches
        </p>
      </label>

      <LegalDisclaimer text='By joining Luna Park' className='text-left' />
    </form>
  );
}

function OrganizationHeader({ organization }: { organization: Organization }) {
  return (
    <header className='mt-4 flex flex-col gap-1'>
      <div className='text-white tracking-wider text-xs lg:text-sm font-medium'>
        Your Organization
      </div>
      <div className='text-tertiary text-3xl lg:text-4xl font-semibold'>
        {organization.name}
      </div>
      <div className='text-white text-sm'>
        Join{' '}
        <strong>
          {new Intl.NumberFormat().format(organization.organizersCount)}
        </strong>{' '}
        of <span className='inline'>your coworkers on Luna Park.</span>
      </div>
    </header>
  );
}

function MainContent(props: {
  organizer: Organizer;
  organization: Organization;
}): JSX.Element {
  const { updateUser } = useUserContext();

  const {
    call: handleSubmit,
    state: { error },
  } = useLiveAsyncCall(async (data: FormData) => {
    if (!data.notificationEnabled) {
      // disable notification first to avoid sending onboarding emails
      await apiService.notification.UpdateSettings({
        value: {
          disabled: true,
        },
      });
    }

    const resp = await apiService.organization.updateOrganizer(
      props.organizer.orgId,
      props.organizer.uid,
      {
        firstName: data.firstName,
        lastName: data.lastName,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        learningGroupId: data.learningGroupId,
      }
    );

    if (resp) {
      updateUser({
        username: data.firstName,
        organizer: resp.data.organizer,
      });
    }
  });

  return (
    <div
      className={`
          h-full w-full
          flex flex-col justify-center 
          mx-auto p-6
          sm:max-w-130
          isolate
        `}
    >
      <div className='z-5 bg-black bg-opacity-80 p-10 rounded-xl'>
        <img src={logo} className='w-12 -ml-2 lg:hidden' alt='Luna Park Logo' />

        <OrganizationHeader organization={props.organization} />

        <div className='text-white font-bold mt-10 mb-4'>
          Let’s activate your account.
        </div>

        <ActivationForm
          defaultValues={useMemo(
            () => ({
              firstName: props.organizer.firstName,
              lastName: props.organizer.lastName,
              notificationEnabled: true,
            }),
            [props.organizer.firstName, props.organizer.lastName]
          )}
          onSubmit={handleSubmit}
          formError={error}
          organization={props.organization}
        />
      </div>
    </div>
  );
}

export function UserActivation(): JSX.Element | null {
  const redirectTo = useRedirectTo();
  const user = useUser({ init: true });
  const organizer = user.organizer;
  const organization = user.organizer?.organization;

  if (!organizer || !organization) {
    return null;
  }

  if (organizer.activated) {
    window.location.replace(redirectTo ?? '/home');
    return <></>;
  }

  return (
    <ConfirmCancelModalProvider>
      <MarketingLayout>
        <MainContent organizer={organizer} organization={organization} />
      </MarketingLayout>

      <ConfirmCancelModalRoot />
    </ConfirmCancelModalProvider>
  );
}
