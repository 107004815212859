// YOU SHOULD NOT NEED TO ADD TO THIS FILE. These are the "legacy" routes used
// before we had SSR and routing conventions. Before you add to this file,
// PLEASE TRY to use /app/routes/* instead.

export const LEGACY_ROUTES = {
  // Main routes
  HOME: '/',
  HOME_REDIRECT: '/home',

  // Venue routes
  MOBILE_VENUE: '/m/venue/:vid/*',
  VENUE: '/venue/:vid/*',
  CLOUD_HOST_VENUE: '/cloud-host/venue/:vid/*',

  // Host
  HOST: '/host/*',

  // User routes
  LIVE: '/live',
  PAIRINGS: '/pairings/:pairingId',
  LEADERBOARDS: '/leaderboards/:roundId',
  LEADERBOARDS_ROUND: '/leaderboards/:roundId/round',
  LEADERBOARDS_GLOBAL: '/leaderboards/:roundId/global',
  MEMORIES: '/memories',
  TOURNAMENTS: '/tournaments/*',
  CHANNELS: '/channels/*',
  ANALYTICS: '/analytics/*',

  // Event routes
  EVENTS: '/events',
  EVENT_DETAIL: '/events/:eventId',
  EVENT_PLAY: '/events/:eventId/play',
  EVENT_COHOST: '/events/:eventId/cohost',

  // Onboarding routes
  ONBOARDING_TASK: '/onboarding/tasks/:taskId',

  // Program routes
  PROGRAMS: '/programs/*',

  // Connection test routes
  CONNECTION_TEST_USER: '/u/connection-test',
  CONNECTION_TEST: '/connection-test',

  // Session routes
  SESSION_MEMORIES: '/sessions/:sessionId/memories',

  // Auth routes
  OAUTH_CALLBACK: '/oauth-callback',
  SIGNUP: '/signup',
  LOGIN: '/login',
  USER_VERIFY: '/user-verify',
  USER_JOIN: '/user-join',
  USER_ACTIVE: '/user-active',

  // Slack routes
  SLACK_CONNECT: '/slack-connect',
  SLACK_CONNECTED: '/slack-connected',

  // Error routes
  ERROR: '/error',
  MOBILE_ACCESS_DENIED: '/mobile-access-denied',
  ACCESS_DENIED: '/access-denied',

  // Storage routes
  STORAGE_HUB: '/storage-hub',

  // Notification routes
  NOTIFICATIONS_SETTINGS: '/notifications/settings',

  // Media routes
  MEDIA: '/media/:mediaId',
} as const;
