import './assets/css/index.css';
import './assets/scss/main.scss';

import { Route } from '@remix-run/react';
import { Navigate, Outlet, Routes } from '@remix-run/react';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';

import { useUserAnalytics } from './analytics/user';
import { LEGACY_ROUTES } from './AppRoutesLegacyPaths';
import {
  AdminAccessDenied,
  LoginModal,
  OAuthCallback,
  RequireActivation,
  RequireToken,
  SignUpModal,
  UserActivation,
  UserVerifyModel,
} from './components/Access';
import { GlobalBlockedAccess } from './components/Access/GlobalBlocked';
import { MobileAccessDenied } from './components/Access/MobileAccessDenied';
import { UserAccess } from './components/Access/UserAccess';
import {
  SlackOneTimeInstall,
  SlackOneTimeInstalled,
} from './components/Channel';
import { GlobalLoading } from './components/GlobalLoading';
import { PairingLandingPage } from './components/Pairing';
import { UserContextProvider } from './components/UserContext';
import config from './config';
import { Error404Page, ErrorPage } from './Error';
import { useBootIntercom } from './hooks/useBootIntercom';
import { useInstance } from './hooks/useInstance';
import { useLoggerConsoleCtrl } from './logger/logger-ctrl';
import { CrossStorageHub } from './pages/CrossStorage';
import { LoginStateSyncer } from './pages/CrossStorage/LoginStateSyncer';
import { store } from './store/configureStore';
import { setAPIServiceClientSecureToken } from './utils/setAPIClientToken';

setAPIServiceClientSecureToken();

const Audience = React.lazy(() => import('./pages/Audience'));
const MobileAudience = React.lazy(() => import('./pages/Audience/MobileVenue'));
const Host = React.lazy(() => import('./pages/Host'));
const ConnectionTest = React.lazy(() => import('./pages/ConnectionTest'));
const CloudHost = React.lazy(() => import('./pages/CloudHost'));
const LeaderboardContainer = React.lazy(() =>
  import('./pages/Leaderboard').then((m) => ({
    default: m.LeaderboardContainer,
  }))
);
const LeaderboardHome = React.lazy(() =>
  import('./pages/Leaderboard').then((m) => ({ default: m.LeaderboardHome }))
);
const LeaderboardDetail = React.lazy(() =>
  import('./pages/Leaderboard').then((m) => ({ default: m.LeaderboardDetail }))
);
const MyMemories = React.lazy(() => import('./pages/Analytics/MyMemories'));
const Media = React.lazy(() => import('./pages/Media'));
const Analytics = React.lazy(() => import('./pages/Analytics'));
const EventPlay = React.lazy(() => import('./pages/Events/MyEventPlay'));
const EventCohost = React.lazy(() => import('./pages/Events/EventCohost'));
const EventList = React.lazy(() => import('./pages/Events/MyEventList'));
const EventDetail = React.lazy(() => import('./pages/Events/MyEventDetail'));
const OnboardTaskDetail = React.lazy(() => import('./pages/Onboard/Task'));
const Live = React.lazy(() => import('./pages/Live'));
const ProgramHome = React.lazy(() => import('./pages/Program'));
const ChannelHome = React.lazy(() => import('./pages/Channel'));
const Memories = React.lazy(() => import('./pages/Memories'));

const NotificationSettings = React.lazy(() => import('./pages/Notification'));

/**
 * Container element for routes that should be blocked.
 */
function BlockableRouteContainer(): JSX.Element {
  return (
    <GlobalBlockedAccess>
      <Outlet />
    </GlobalBlockedAccess>
  );
}

/**
 * Container element for routes that should require user activation.
 */
function RequireActivationContainer(): JSX.Element {
  return (
    <RequireActivation>
      <Outlet />
    </RequireActivation>
  );
}

function AppInit() {
  useBootIntercom(
    useInstance(() => [
      LEGACY_ROUTES.HOME_REDIRECT,
      LEGACY_ROUTES.LIVE,
      LEGACY_ROUTES.EVENTS,
      LEGACY_ROUTES.PROGRAMS,
      LEGACY_ROUTES.CHANNELS,
      LEGACY_ROUTES.ANALYTICS,
    ])
  );
  return (
    <Routes>
      <Route
        // TODO: consider removing this, we don't need to worry about the old
        // domain anymore at al
        element={
          <LoginStateSyncer
            enabled={config.crossStorage.enabled}
            origin={config.crossStorage.origin}
          />
        }
      >
        <Route
          path={LEGACY_ROUTES.MOBILE_VENUE}
          element={
            <Suspense fallback={null}>
              <MobileAudience />
            </Suspense>
          }
        />
        <Route element={<BlockableRouteContainer />}>
          {/* users should be able to play games without activation */}
          <Route
            path={LEGACY_ROUTES.VENUE}
            element={
              <Suspense fallback={null}>
                <Audience />
              </Suspense>
            }
          />
          <Route
            path={LEGACY_ROUTES.EVENT_PLAY}
            element={
              <Suspense fallback={<GlobalLoading />}>
                <EventPlay />
              </Suspense>
            }
          />

          <Route element={<RequireActivationContainer />}>
            <Route
              path={LEGACY_ROUTES.HOME}
              element={<Navigate to={LEGACY_ROUTES.HOME_REDIRECT} />}
            />

            <Route
              path={LEGACY_ROUTES.CLOUD_HOST_VENUE}
              element={
                <Suspense fallback={<GlobalLoading />}>
                  <CloudHost />
                </Suspense>
              }
            />

            <Route element={<RequireToken loginType='password' />}>
              <Route
                path={LEGACY_ROUTES.HOST}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <Host />
                  </Suspense>
                }
              />
            </Route>

            <Route element={<UserAccess />}>
              <Route
                path={LEGACY_ROUTES.LIVE}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <Live />
                  </Suspense>
                }
              />
              <Route
                path={LEGACY_ROUTES.PAIRINGS}
                element={<PairingLandingPage />}
              />
              <Route
                path={LEGACY_ROUTES.LEADERBOARDS}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <LeaderboardContainer />
                  </Suspense>
                }
              >
                <Route index element={<LeaderboardHome />} />
                <Route
                  path='round'
                  element={<LeaderboardDetail type='round' />}
                />
                <Route
                  path='global'
                  element={<LeaderboardDetail type='global' />}
                />
              </Route>
              <Route
                path={LEGACY_ROUTES.MEMORIES}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <MyMemories />
                  </Suspense>
                }
              />
              <Route
                path={LEGACY_ROUTES.TOURNAMENTS}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <Navigate to={LEGACY_ROUTES.CHANNELS} replace />;
                  </Suspense>
                }
              />
              <Route
                path={LEGACY_ROUTES.CHANNELS}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <ChannelHome />
                  </Suspense>
                }
              />
              <Route
                path={LEGACY_ROUTES.ANALYTICS}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <Analytics />
                  </Suspense>
                }
              />
              <Route
                path={LEGACY_ROUTES.EVENTS}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <EventList />
                  </Suspense>
                }
              />
              <Route
                path={LEGACY_ROUTES.EVENT_DETAIL}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <EventDetail />
                  </Suspense>
                }
              />
              <Route
                path={LEGACY_ROUTES.EVENT_COHOST}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <EventCohost />
                  </Suspense>
                }
              />
              <Route
                path={LEGACY_ROUTES.ONBOARDING_TASK}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <OnboardTaskDetail />
                  </Suspense>
                }
              />
              <Route
                path={LEGACY_ROUTES.PROGRAMS}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <ProgramHome />
                  </Suspense>
                }
              />

              <Route
                path={LEGACY_ROUTES.CONNECTION_TEST_USER}
                element={
                  <Suspense fallback={<GlobalLoading />}>
                    <ConnectionTest />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>

        {/* note: memories page allows guest user access. */}
        <Route element={<RequireToken />}>
          <Route
            path={LEGACY_ROUTES.SESSION_MEMORIES}
            element={
              <Suspense fallback={<GlobalLoading />}>
                <Memories />
              </Suspense>
            }
          />
        </Route>

        <Route
          path={LEGACY_ROUTES.OAUTH_CALLBACK}
          element={<OAuthCallback />}
        />
        <Route path={LEGACY_ROUTES.SIGNUP} element={<SignUpModal />} />
        <Route path={LEGACY_ROUTES.LOGIN} element={<LoginModal />} />
        <Route
          path={LEGACY_ROUTES.USER_VERIFY}
          element={<UserVerifyModel verifyType='login' />}
        />
        <Route
          path={LEGACY_ROUTES.USER_JOIN}
          element={<UserVerifyModel verifyType='invite' />}
        />
        <Route path={LEGACY_ROUTES.USER_ACTIVE} element={<UserActivation />} />
        <Route
          path={LEGACY_ROUTES.SLACK_CONNECT}
          element={<SlackOneTimeInstall />}
        />
        <Route
          path={LEGACY_ROUTES.SLACK_CONNECTED}
          element={<SlackOneTimeInstalled />}
        />
        <Route path={LEGACY_ROUTES.ERROR} element={<ErrorPage />} />
        <Route
          path={LEGACY_ROUTES.CONNECTION_TEST}
          element={
            <Suspense fallback={<GlobalLoading />}>
              <ConnectionTest />
            </Suspense>
          }
        />
        <Route
          path={LEGACY_ROUTES.MOBILE_ACCESS_DENIED}
          element={<MobileAccessDenied />}
        />
        <Route
          path={LEGACY_ROUTES.ACCESS_DENIED}
          element={<AdminAccessDenied />}
        />
        <Route
          path={LEGACY_ROUTES.STORAGE_HUB}
          element={<CrossStorageHub origin={config.crossStorage.origin} />}
        />
        <Route
          path={LEGACY_ROUTES.NOTIFICATIONS_SETTINGS}
          element={
            <Suspense fallback={<GlobalLoading />}>
              <NotificationSettings />
            </Suspense>
          }
        />
      </Route>
      <Route
        path={LEGACY_ROUTES.MEDIA}
        element={
          <Suspense fallback={<GlobalLoading />}>
            <Media />
          </Suspense>
        }
      />
      <Route path='*' element={<Error404Page capture />} />
    </Routes>
  );
}

function AppRoutes() {
  useLoggerConsoleCtrl();
  return (
    <Provider store={store}>
      <UserContextProvider useUserAnalytics={useUserAnalytics}>
        <IntercomProvider appId={config.misc.intercomAppId}>
          <AppInit />
        </IntercomProvider>
      </UserContextProvider>
    </Provider>
  );
}

export const Component = AppRoutes;
