import { useNavigate } from '@remix-run/react';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useAsyncCall } from '../../hooks/useAsyncCall';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { LegalDisclaimer } from '../common/Utilities';
import { Loading } from '../Loading';
import {
  buildSearchParamsWithRedirectToAsString,
  useRedirectTo,
} from './hooks';
import { LoginHeader, LoginModalLayoutV2 } from './LoginModalLayout';
import {
  EMAIL_PATTERN,
  type LoginSuccessCallback,
  type SetLoginType,
} from './types';

interface FormData {
  email: string;
  password: string;
}

export const LoginModalPassword = ({
  setLoginType,
  onLoginSuccess: onLogin,
}: {
  setLoginType: SetLoginType;
  onLoginSuccess: LoginSuccessCallback;
}): JSX.Element => {
  const redirectTo = useRedirectTo();
  const navigate = useNavigate();
  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback(async (data: FormData) => {
      return (await apiService.auth.login(data)).data;
    }, [])
  );

  const { register, handleSubmit, formState } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = handleSubmit(async (data: FormData) => {
    const resp = await call(data);
    if (resp) {
      onLogin(resp);
    }
  });

  const unsetLoginType = () => {
    setLoginType(null);
    navigate({
      pathname: '/login',
      search: buildSearchParamsWithRedirectToAsString(redirectTo),
    });
  };

  return (
    <LoginModalLayoutV2>
      <div className='absolute text-white w-full flex justify-center'>
        <LoginHeader noSlogan />
        <div
          className='w-max h-full flex flex-col items-center bg-black
    bg-opacity-80 border border-secondary rounded-2.25xl 
    pt-10 pb-4 px-5 lg:px-15 gap-10'
        >
          <form
            onSubmit={onSubmit}
            className='w-80 lg:w-100 flex flex-col justify-center items-center gap-3'
          >
            <div className='flex flex-col justify-center items-center gap-2'>
              <p className='text-xl font-medium'>Experience what’s next!</p>
              <p className='text-sms text-center'>
                Welcome to Luna Park, the world’s first gaming platform for
                teams.
              </p>
            </div>

            <div className='w-full flex flex-col justify-center items-center gap-2.5'>
              <div className='font-bold'>Log in with an email and password</div>

              <label className='w-full flex flex-col items-center'>
                <input
                  className={`w-full h-12.5 ${
                    formState.errors.email ? 'field-error' : 'field'
                  } mb-0`}
                  placeholder='Email'
                  {...register('email', {
                    required: true,
                    pattern: EMAIL_PATTERN,
                  })}
                ></input>
              </label>

              <label className='w-full flex flex-col items-center'>
                <input
                  type='password'
                  className={`w-full h-12.5 ${
                    formState.errors.password ? 'field-error' : 'field'
                  } mb-0`}
                  placeholder='Password'
                  {...register('password', {
                    required: true,
                  })}
                ></input>
              </label>
            </div>

            <div className='w-80 text-3xs font-medium text-red-002 text-center h-3'>
              {Object.keys(formState.errors).length > 0
                ? 'Incorrect username or password'
                : error && err2s(error)}
            </div>

            <button
              type='submit'
              className='btn btn-primary w-full h-12.5 flex justify-center items-center'
              disabled={state.isRunning}
            >
              {state.isRunning && <Loading text='' containerClassName='mr-2' />}
              Login
            </button>

            <button
              type='button'
              onClick={unsetLoginType}
              className='flex items-center text-icon-gray font-medium text-sms'
            >
              Login with Google/OTL
            </button>

            <LegalDisclaimer text='By joining Luna Park' />
          </form>
        </div>
      </div>
    </LoginModalLayoutV2>
  );
};
